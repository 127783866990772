body {
  margin: 0;
}

.app {
  text-align: center;
}

.app-logo {
  height: 60vmin;
  pointer-events: none;
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.app-footer {
  background-color: #282c34;
  padding-bottom: 2em;
}

.link {
  color: white;
}

.first-link {
  padding-right: 2em;
}

.text {
  margin-top: 5em;
  font-size: 1.2rem;
}

.icons-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 20%;
}

.social-media-icons {
  color: white;
  opacity: 0.8;
}

.social-media-icons:hover {
  opacity: 1;
}

.icon-separator {
  margin-right: 1em;
}

.privacy-policy-content {
  width: 70vw;
}
